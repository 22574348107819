<template>
  <v-sheet
    height="51px"
    width="100%"
    color="wineSecondary"
    rounded="lg"
    class="d-flex align-center rounded-lg white--text text-subtitle-1">
    <v-row
      class="d-flex align-center">
      <v-col
        :class="colClass">
        <span
          :class="titleClass">
          Vidas
        </span>
      </v-col>
      <v-col
        :class="colClass">
        <span
          :class="titleClass">
          Prêmio
        </span>
      </v-col>
      <v-col
        :class="colClass">
        <span
          :class="titleClass">
          Iof
        </span>
      </v-col>
      <v-col
        :class="colClass">
        <span
          :class="titleClass">
          Total de outros valores
        </span>
      </v-col>
      <v-col
        :class="colClass">
        <span
          :class="titleClass">
          Retroatividade
        </span>
      </v-col>
      <v-col
        :class="colClass">
        <span
          :class="titleClass">
          Prêmio Total
        </span>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "RegistersHeader",

  data: () => ({
    titleClass: 'text-subtitle-1 white--text',
    colClass: 'd-flex justify-center',
  })
}
</script>
