<template>
  <v-container
    fluid>
    <v-row
      v-if="invoiceValidationDetails.length > 0">
      <v-col
        cols="12">
        <v-row
          no-gutters>
          <v-col
            cols="6">
            <div
              class="d-flex flex-row align-center justify-start">
              <v-btn
                class="rounded-pill wine"
                icon
                height="42"
                width="42"
                @click="close">
                <v-icon
                  color="white"
                  size="18">
                  fas fa-arrow-left
                </v-icon>
              </v-btn>
              <span
                class="ml-2 wine--text text-subtitle-1">
                Voltar
              </span>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="d-flex align-center justify-end">
            <v-btn
              height="50"
              width="263"
              class="wine white--text rounded-pill text-subtitle-1"
              :loading="loadingExport"
              @click="exportInvoice"
              v-show="hasPermission('sdi_faturas_conferencia_detalhe_exportar')">
              Exportar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols=12>
        <v-row
          no-gutters
          style="background-color: #EDE5EB;"
          class="py-2 px-8">
          <v-col
            cols="12"
            xl="2"
            lg="3"
            md="6"
            sm="6"
            class="d-flex flex-column justify-center align-start">
            <p
              v-bind="defaultTitleProps">
              Operadora
            </p>
            <p
              v-bind="defaultValueProps"
              :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 280px;' : 'max-width: 350px;'">
              <v-tooltip
                bottom>
                <template
                  v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="defaultValueProps">
                    {{ invoiceValidationDetails[0].carrier.name }}
                  </div>
                </template>
                {{ invoiceValidationDetails[0].carrier.name }}
              </v-tooltip>
            </p>
          </v-col>
          <v-col
            cols="12"
            xl="2"
            lg="3"
            md="6"
            sm="6"
            class="d-flex flex-column justify-center align-start">
            <p
              v-bind="defaultTitleProps">
              Grupo financeiro
            </p>
            <p
              :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 280px;' : 'max-width: 350px;'"
              v-bind="defaultValueProps">
              <v-tooltip
                bottom>
                <template
                  v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="defaultValueProps">
                    {{ invoiceValidationDetails[0].financialGroup.name }}
                  </div>
                </template>
                {{ invoiceValidationDetails[0].financialGroup.name }}
              </v-tooltip>
            </p>
          </v-col>
          <v-col
            cols="12"
            xl="2"
            lg="2"
            md="6"
            sm="6"
            class="d-flex flex-column justify-center align-start">
            <p
              v-bind="defaultTitleProps">
              Apólice
            </p>
            <p
              v-bind="defaultValueProps">
              {{ invoiceValidationDetails[0].policy }}
            </p>
          </v-col>
          <v-col
            class="d-flex flex-column justify-center align-start"
            cols="12"
            xl="2"
            lg="2"
            md="6"
            sm="6">
            <p
              v-bind="defaultTitleProps">
              Sub
            </p>
            <p
              v-bind="defaultValueProps">
              {{ invoiceValidationDetails[0].subContractNumber }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      item-key="id"
      :items="invoiceValidationDetailsItems.filter(
        invoice => setInvoiceStatus(invoice)
      )"
      :loading="loading"
      :items-per-page="itemsPerPage"
      :page="page"
      @update:page="resetCheckbox"
      :custon-sort="{
        isDescending: true
      }"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 75, 100],
        disablePagination: loading
      }"
      hide-default-header>
      <template
        v-slot:no-data>
        <span>
          Nenhum dado encontrado.
        </span>
      </template>
      <template
        v-slot:header>
        <v-col
          cols="12"
          class="d-flex flex-row justify-end align-center pt-0 mt-3 px-0">
          <v-select
            v-bind="autoCompleteProps"
            v-model="invoiceStatus"
            class="mr-4"
            label="Status"
            :items="[
              {id: 'SUCCESS', name: 'Validado'},
              {id: 'ERROR', name: 'Com erro'},
              {id: 'NOT_FOUND', name: 'Não localizado'},
            ]"
            style="max-width: 250px;"
          />
          <v-select
            v-bind="autoCompleteProps"
            v-model="reasonCriticism"
            label="Motivo da Crítica"
            :items="criticismItems"
            style="max-width: 250px;"
          />
          <v-spacer />
          <span
            class="wine--text font-weight-light text-subtitle-2 mr-2">
            Ação em massa
          </span>
          <v-menu
            transition="slide-y-transition"
            offset-y>
            <template
              v-slot:activator="{ on, attrs }">
              <div
                class="wineSecondary d-flex justify-center align-center rounded-pill mr-2"
                style="height: 34px; width: 34px;"
                v-bind="attrs"
                v-on="on">
                <v-icon
                  class="white--text">
                  mdi-dots-vertical
                </v-icon>
              </div>
            </template>
            <TodoListActions
              :actions="todoActions()"
            />
          </v-menu>
          <v-checkbox
            v-model="selectAllInvoices"
            color="wine"
            class="ma-0 pt-0 ml-2 mr-12"
            hide-details
          />
        </v-col>
        <RegistersHeader />
      </template>
      <template
        v-slot:item="{ item }">
        <RegistersTable
          :item="item"
          :dependents="item.beneficiaryType === 'Titular' ? getDependentsHolder(item) : []"
          :selectAllInvoices="selectAllInvoices"
          :invoicesToValidate="invoicesToValidate"
          @filesToReprocess="filesToReprocess = $event"
          @openCriticsInvoiceModal="openCriticsInvoiceModal"
          @dependentsToRemove="dependentsToRemove">
          <template
            slot="register-checkbox">
            <v-checkbox
              hide-details
              :value="item.validationDetailId"
              v-model="invoicesToValidate"
              color="wine"
              class="ma-0 pa-0"
              multiple
              v-if="!item.checked && item.status !== 'SUCCESS'"
            />
          </template>
        </RegistersTable>
      </template>
    </v-data-table>
    <CriticsInvoiceModal
      ref="CriticsInvoiceModal"
      :hasComment="false"
    />
    <ConfirmationCommentModal
			ref="ConfirmationCommentModal"
			@confirm="confirmValidation"
			:title="'Validar registro'"
			:description="'Descreva abaixo a razão para validar estes registros.'"
		/>
    <Loader :overlay="loadingOverlay" />
    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>
<script>

import ConfirmationCommentModal from '../Modals/ConfirmationCommentModal/ConfirmationCommentModal.vue';
import CriticsInvoiceModal from '../Modals/CriticsInvoiceModal/CriticsInvoiceModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import RegistersHeader from './RegistersHeader.vue';
import Loader from '@/components/Loader/Loader.vue'
import RegistersTable from './RegistersTable.vue';

import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import OperationalService from "@/services-http/operational/OperationalService.js";

export default {
  name: "InvoiceDetails",

  components: {
    ConfirmationCommentModal,
    CriticsInvoiceModal,
    SnackbarCustomize,
    TodoListActions,
    RegistersHeader,
    RegistersTable,
    Loader,
  },

  data: () => ({
    loadingExport: false,
		loadingOverlay: false,
    loading: false,

    operationalService: null,
    reasonCriticism: null,
    invoiceStatus: null,

    itemsPerPage: 25,
    page: 1,

    defaultTitleProps: {
			class: "wineLabel--text text-subtitle-1 font-weight-medium pb-0 mb-0",
		},
		defaultValueProps: {
			class: "wineLabel--text text-subtitle-1 font-weight-regular text-truncate pb-0 mb-0",
		},
    selectAllInvoices: false,
    invoicesToValidate: [],
    autoCompleteProps: {
			placeholder: "Selecione",
			itemText: "name",
			itemValue: "id",
			outlined: true,
			clearable: true,
			dense: true,
      hideDetails: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
  }),

  mixins: [
    AuthorityManagementMixin
  ],

  watch: {
    selectAllInvoices: {
      handler(val) {
				if (val) {
          const start = (this.page - 1) * this.itemsPerPage;
          const end = this.page * this.itemsPerPage;

          let invoices = this.invoiceValidationDetailsItems
            .filter(invoice =>
              this.setInvoiceStatus(invoice))
            .slice(start, end);

          let dependents = [];

          invoices.map(invoice => {
            dependents = [...dependents, ...this.getDependentsHolderId(invoice)]
          });

          invoices = invoices.filter(
            invoice => invoice.checked === false && invoice.status !== 'SUCCESS');

          this.invoicesToValidate = invoices.map(el => el.validationDetailId);

          this.invoicesToValidate = [...this.invoicesToValidate, ...dependents];
          this.invoicesToValidate = Array.from(
            new Set(this.invoicesToValidate)
          );

				} else {
					this.invoicesToValidate = [];
				}
			},
			immediate: true,
			deep: true,
    },
    invoiceValidationDetails: {
      handler(val) {
				if (val) {
					this.resetData();
        }
			},
			immediate: true,
			deep: true,
    },
  },

  props: {
    card: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    resetCheckbox(event) {
      this.page = event;
      this.invoicesToValidate = [];
      this.selectAllInvoices = false;
    },
    dependentsToRemove(data) {
      const { addIds, removeIds } = data;

      this.invoicesToValidate = Array.from(new Set([ ...this.invoicesToValidate, ...addIds ]));

      if (removeIds.length > 0) {
        removeIds.forEach(validationDetailId => {
          const validationDetailIdIndex = this.invoicesToValidate.findIndex(el => el === validationDetailId);
          if (validationDetailIdIndex) {
            this.invoicesToValidate.splice(validationDetailIdIndex, 1);
          }
        })
      }
    },
    todoActions() {
      const actions = [
      {
        label: 'Validar',
        icon: 'fa-check-double',
        color: 'wineLabel',
        condition: this.hasPermission('sdi_faturas_conferencia_detalhe_validar'),
        disabled: this.invoicesToValidate.length === 0,
        handler: () => this.openConfirmationCommentModal(),
      },
    ];

      return actions;
    },
    openConfirmationCommentModal() {
			this.$refs.ConfirmationCommentModal.open(null);
		},
    async confirmValidation(obj) {
			this.loadingOverlay = true;
			const formData = new FormData();
      formData.append('validationDetailIds', this.invoicesToValidate);
      formData.append('comment', obj.comment);
      formData.append('checked', true);

			await this.operationalService.CheckValidationDetail(formData).then(() => {
				this.$refs.SnackbarCustomize.open('success', 'Registro validado com sucesso.');
        this.invoicesToValidate.forEach((validationDetailId) => {
          operationalRoutinesModule.updateInvoiceValidationDetail(validationDetailId);
        })
        this.invoicesToValidate = [];
        this.selectAllInvoices = false;
        this.$emit('updateInvoiceValidationPage')
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar validar o registro.');
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
    close() {
      this.resetData();
      this.$emit('close')
    },
    resetData() {
      this.selectAllInvoices = false;
      this.invoicesToValidate = [];
      this.invoiceStatus = null;
      this.reasonCriticism = null;
      this.page = 1;
      this.itemsPerPage = 25;
    },
    setInvoiceValidationFilter(invoice) {
      if (Object.entries(this.card).length === 0) {
        return Object.entries(invoice).length > 0;
      } else {
        return this.card.ids.includes(invoice.validationDetailId)
      }
    },
    setInvoiceStatus(invoice) {
      const criticismValidation = this.reasonCriticism ?
        invoice.criticismList.some(
        critic => critic === this.reasonCriticism
      ) : true;

      const statusValidation = this.invoiceStatus
        ? invoice.status === this.invoiceStatus
        : true

      return criticismValidation && statusValidation;
    },
    async exportInvoice() {
      const invoices = this.invoiceValidationDetails.filter(
        (item, index, self) => index === self.findIndex((t) => t.validationId === item.validationId)
      );

      this.loadingExport = true;

      for (const invoice of invoices) {
        const param = new URLSearchParams({validationId: invoice.validationId});

        await this.operationalService.CreateReport(param).then((response) => {
          if (response) {
            let filename = `${invoice.invoiceDate}_${invoice.policy}_${invoice.subContractNumber}_${invoice.companyName}`;
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${filename}.xlsx`);
            document.body.appendChild(fileLink);

            fileLink.click();
            this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
          }
        }).catch((e) => {
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o download.');
          this.loadingExport = false;
        });
      }

      this.loadingExport = false;
		},
    async openCriticsInvoiceModal(validationDetailId) {
      await Promise.all([
        this.operationalService.GetValidationDetailCriticism(validationDetailId),
        this.operationalService.GetValidationDetailComment(validationDetailId)
      ])
      this.$refs.CriticsInvoiceModal.open(
        operationalRoutinesModule.detailCriticism,
        operationalRoutinesModule.detailComment
      );
    },

    getDependentsHolder(invoice) {
      return operationalRoutinesModule.invoiceValidationDetail.filter(
        item => item.beneficiaryType === 'Dependente' && item.holderId !== null && item.holderId === invoice.holderId
      );
    },

    getDependentsHolderId(invoice) {
      return operationalRoutinesModule.invoiceValidationDetail.filter(
        item => item.beneficiaryType === 'Dependente'
          && invoice.beneficiaryType !== 'Dependente'
          && item.holderId !== null
          && item.holderId === invoice.holderId
          && item.checked === false
          && item.status !== 'SUCCESS'
      ).map(obj => obj.validationDetailId);
    },
  },

  computed: {
    criticismItems() {
      let criticism = [];

      this.invoiceValidationDetailsItems.forEach(item => {
        criticism = [...criticism, ...item.criticismList];
      });

      return Array.from(criticism).sort((a, b) => (a > b ? 1 : -1));
    },
    invoiceValidationDetailsItems() {
      return this.invoiceValidationDetails.filter(
        invoice => this.setInvoiceValidationFilter(invoice)
      );
    },
    invoiceValidationDetails() {
      const holders = operationalRoutinesModule.invoiceValidationDetail.filter(
        item => item.beneficiaryType !== 'Dependente')
      const dependents = operationalRoutinesModule.invoiceValidationDetail.filter(
        item => item.beneficiaryType === 'Dependente'
          && (
          item.holderId === null
          || !(
            operationalRoutinesModule.invoiceValidationDetail.some(
              line => line.holderId === item.holderId
            ))
          || (
            operationalRoutinesModule.invoiceValidationDetail.some(
              line => line.holderId === item.holderId
                && line.checked === true
                && item.checked === false
              ))
        )
      )
      return [...holders, ...dependents].sort(
        (a, b) => (a.validationDetailId < b.validationDetailId ? 1 : -1));
    },
    invoiceValidations() {
      return operationalRoutinesModule.invoiceValidations;
    },
  },

	created() {
    this.operationalService = new OperationalService();
	},
}
</script>
