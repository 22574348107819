<template>
  <v-sheet
    class="elevation-3 rounded-lg d-flex align-center mr-1"
    min-width="1400">
    <v-row
      no-gutters>
      <v-col
        v-bind="defaultColumnProps"
        cols="1">
        <v-hover
          v-slot="{ hover }">
          <v-expand-x-transition>
            <v-sheet
              :class="{
                'd-flex align-center rounded-l-lg rounded-r-0 overflow-visible text-no-wrap': true,
                'expand-enter': hover,
                'expand-leave': !hover,
              }"
              height="100%"
              :width="hover ? '500px' : '70px'"
              :color="
                item.beneficiaryType === 'Titular'
                  ? 'wineSecondary'
                  : 'wineTitle'"
              style="z-index: 2;">
              <div
                style="
                  position: relative;
                  height: 70px;
                  width: 70px;
                  min-width: 70px;">
                <div
                  style="
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);">
                  <span
                    v-bind="
                      item.beneficiaryType === 'Titular'
                      ? defaultTypeProps
                      : defaultDependentTypeProps">
                    {{ item.invoiceType }}
                  </span>
                </div>
              </div>
              <div
                :class="{
                  'pr-2': true,
                  'expand-text-enter': hover,
                  'expand-text-leave': !hover,
                }"
                style="min-width: 250px;">
                <span
                  v-if="hover"
                  v-bind="defaultTypeValueProps">
                  {{ getRegisterTypes(item.invoiceType) }}
                </span>
              </div>
            </v-sheet>
          </v-expand-x-transition>
        </v-hover>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        cols="3">
        <v-row
          no-gutters
          class="text-truncate">
          <v-col
            cols="12">
            <div
              v-bind="mainSpanProps"
              style="max-width: auto;">
              <v-tooltip
                bottom>
                <template
                  v-slot:activator="{ on, attrs}">
                  <span
                    v-bind="attrs"
                    v-on="on">
                    {{ item.beneficiaryName }}
                  </span>
                </template>
                <span>
                  {{ item.beneficiaryName }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="12">
            <div
              v-bind="defaultSpanProps">
              {{
                item.beneficiaryDocumentNumber
                ? formatter.formatCpf(item.beneficiaryDocumentNumber.toString())
                : '—'
              }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="3">
        <v-sheet
          class="rounded-lg d-flex align-center justify-start pl-3 my-1"
          :color="
            item.beneficiaryType === 'Titular'
            ? 'wineSecondary'
            : 'wineTitle'"
          height="70"
          width="100%">
          <v-row
            v-bind="defaultSpanWhiteProps">
            <v-col
              cols="12">
              <v-row
                no-gutters>
                <v-col
                  cols="12">
                  <div>
                    {{ item.planCode }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                  ">
                  {{ item.planDescription }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col
        class="d-flex align-center pl-5"
        cols="1">
        <div
          v-bind="mainSpanProps">
          {{ formatCurrency(item.premiumValue) }}
        </div>
      </v-col>
      <v-col
        cols="1"
        class="d-flex align-center justify-start">
        <v-row
          no-gutters>
          <v-col
            cols="12">
            <div
              v-bind="mainSpanProps">
              {{ item.beneficiaryType }}
            </div>
          </v-col>
          <v-col
            col="12"
            class="d-flex flex-row"
            v-if="item.beneficiaryType === 'Titular' && dependents.length > 0">
            <div
              v-bind="defaultSpanProps"
              class="pr-2">
              {{ dependents.length }}
            </div>
            <v-icon
              size="20"
              color="#FDBC2C"
              v-if="dependents.some(dep => dep.status != 'SUCCESS')">
              fas fa-exclamation-triangle
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        cols="1">
        <v-tooltip
          bottom>
          <template
            v-slot:activator="{ on, attrs }">
            <v-sheet
              v-on="on"
              v-bind="attrs"
              height="36"
              width="36"
              min-width="36"
              class="rounded-circle d-flex justify-center align-center"
              :color="setInvoiceStatus(item.status).color">
              <v-icon
                color="white"
                size="15">
                {{ setInvoiceStatus(item.status).icon }}
              </v-icon>
            </v-sheet>
            <span
              v-on="on"
              class="pl-2"
              v-bind="mainSpanProps">
              {{ setInvoiceStatus(item.status).label }}
            </span>
          </template>
          <span>
            {{ setInvoiceStatus(item.status).label }}
          </span>
        </v-tooltip>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        cols="1"
        class="pl-0"
        style="min-width: 50px;">
        <v-tooltip
          bottom>
          <template
            v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="d-flex flex-row align-center">
              <v-sheet
                height="36"
                width="36"
                min-width="36"
                class="rounded-circle d-flex justify-center align-center"
                :color="item.checked ? '#5DB884' : 'wineTitle'">
                <v-icon
                  color="white"
                  size="15">
                  fas fa-check
                </v-icon>
              </v-sheet>
              <span
                class="pl-2"
                v-bind="mainSpanProps">
                {{ item.checked ? 'Conferido' : 'Não conferido' }}
              </span>
            </div>
          </template>
          <span>
            {{ item.checked ? 'Conferido' : 'Não conferido' }}
          </span>
        </v-tooltip>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        cols="1"
        class="d-flex justify-end pr-4">
        <div
          class="d-flex align-center justify-end mr-4">
          <v-menu
            transition="slide-y-transition"
            offset-y>
            <template
              v-slot:activator="{ on, attrs }">
              <div
                class="wineSecondary d-flex justify-center align-center rounded-pill"
                style="height: 34px; width: 34px;"
                v-bind="attrs"
                v-on="on">
                <v-icon
                  class="white--text">
                  mdi-dots-vertical
                </v-icon>
              </div>
            </template>
            <TodoListActions :actions="getTodoActions(item)" />
          </v-menu>
        </div>
        <div
          style="width: 24px; max-width: 24px;"
          class="d-flex align-end">
          <slot name="checkbox"></slot>
        </div>
        <div>
          <slot name="expanded"></slot>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default {
	name: "RegisterCard",

	components: {
		TodoListActions,
	},

	data: () => ({
		formatter: null,
		expanded: null,

		defaultColumnProps: {
			class: "d-flex justify-start align-center"
		},
		defaultTitleProps: {
			class: "wineTitle--text text-body-2 font-weight-regular text-truncate",
		},
		defaultSpanProps: {
			class: "wineTitle--text text-body-2 font-weight-regular text-truncate",
		},
		defaultSpanWhiteProps: {
			class: "white--text text-body-2 font-weight-regular text-truncate",
		},
		mainSpanProps: {
			class: "wineLabel--text text-body-2 font-weight-regular text-truncate",
		},
		defaultTypeProps: {
			class: "wine--text text-h6 font-weight-medium text-truncate",
		},
		defaultDependentTypeProps: {
			class: "white--text text-h6 font-weight-medium text-truncate",
		},
		defaultTypeValueProps: {
			class: "wineLabel--text text-subtitle-1 font-weight-medium text-truncate",
		},
	}),

	props: {
		item: null,
		dependents: {
      type: Array,
      required: false,
			default: () => []
    },
	},

	mixins: [
		OperationalRoutinesMixin,
		AuthorityManagementMixin,
	],

	methods: {
		openCriticsInvoiceModal(validationDetailId) {
			this.$emit("openCriticsInvoiceModal", validationDetailId);
		},
		formatCurrency(value) {
      if (value != 0) return this.formatter.formatToBRLCurrency(value);
      return "—";
    },
		openConfirmationCommentModal(validationDetailId) {
      this.$emit('openConfirmationCommentModal', validationDetailId);
		},
		getTodoActions(item) {
      const actions = [
        {
          label: 'Exibir criticas',
          icon: 'fas fa-comment-dots',
          color: 'wineLabel',
          condition: true,
          disabled: false,
          handler: () => this.openCriticsInvoiceModal(item.validationDetailId),
        },
        {
          label: 'Validar',
          icon: 'fa-check',
          color: 'wineLabel',
          condition: this.hasPermission('sdi_faturas_conferencia_detalhe_validar'),
          disabled: item.status === 'SUCCESS',
          handler: () => this.openConfirmationCommentModal(item.validationDetailId),
        },
      ];

      return actions;
    },
		setInvoiceStatus(status) {
			switch(status) {
				case 'SUCCESS':
					return {
						icon: 'fas fa-check',
						label: 'Validado',
						color: '#5DB884'
					}
				case 'ERROR':
					return {
						icon: 'fas fa-exclamation',
						label: 'Com erro',
						color: '#F73A3B'
					}
				case 'NOT_FOUND':
					return {
						icon: 'fas fa-search',
						label: 'Não localizado',
						color: '#FDBC2C'
					}
			}
		}
	},

	computed: {
		syncIconTransition() {
			return {
        transform: this.expanded !== 0 ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.25s',
      };
		},
	},

	created() {
		this.formatter = new Formatters();
	},
}
</script>
<style scoped>
.expand-enter,
.expand-leave {
	transition: width .7s ease;
}

.expand-text-enter {
	transition: width .7s ease;
	transition: opacity .7s ease;
	opacity: 1;
}

.expand-text-leave {
	transition: width .7s ease;
	transition: opacity .7s ease;
	transition: display 1s ease;
	display: 0;
	opacity: 0;
}
.syncSearchTransition {
	transform: 0.5s ease-out;
  animation: rotate 1.2s linear infinite;
}
</style>