<template>
  <v-alert
    outlined
    color="transparent"
    class="pa-0 white">
    <v-row>
      <v-col
        md="5"
        sm="12">
        <v-list
          dense
          height="200"
          style="overflow-y: auto;"
          outlined
          class="rounded-lg">
          <v-subheader
            class="text-body-2">
            Opções disponíveis
          </v-subheader>
          <v-divider />
          <v-list-item-group
            multiple
            v-model="availableIndexOptions"
            class="fill-height">
            <v-list-item
              v-for="(option, i) in availableOptions"
              :key="i">
              <template
                v-slot:default="{ active }">
                <v-list-item-content
                  @click="clearRemove()">
                  <v-list-item-title v-text="option.name" />
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    small
                    v-if="active"
                    color="primary lighten-1">
                    fas fa-angle-double-right
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col
        md="2"
        sm="12"
        class="d-flex flex-column align-center justify-center">
        <v-btn
          block
          max-height="40"
          :disabled="addButtonDisabled"
          @click="addSelecteds()"
          class="my-1">
          Adicionar
          <v-icon
            right
            dark>
            fas fa-angle-double-right
          </v-icon>
        </v-btn>

        <v-btn
          block
          max-height="40"
          :disabled="removeButtonDisabled"
          @click="removeSelecteds()"
          class="my-1">
          <v-icon
            left
            dark>
            fas fa-angle-double-left
          </v-icon>
          Remover
        </v-btn>
      </v-col>

      <v-col
        md="5"
        sm="12">
        <v-list
          dense
          height="200"
          style="overflow-y: auto;"
          outlined
          class="rounded-lg">
          <v-subheader
            class="text-body-2">
            Opções selecionadas
          </v-subheader>
          <v-divider />
          <v-list-item-group
            multiple
            class="fill-height"
            v-model="availableIndexOptionsToRemove">
            <v-list-item
              v-for="(option, i) in selectedOptions"
              :key="i">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-icon
                    small
                    v-if="active"
                    color="primary lighten-1">
                    fas fa-angle-double-left
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content
                  @click="clearAdd()"
                  align="right">
                  <v-list-item-title v-text="option.name" />
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "DualListBox",

  data: () => ({
    addButtonDisabled: true,
    availableIndexOptions: [],
    availableIndexOptionsToRemove: [],
    removeButtonDisabled: true,
  }),

  props: {
    availableOptions: {
      type: Array,
      default: []
    },
    selectedOptions: {
      type: Array,
      default: []
    },
    verifyFields: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    availableIndexOptions: {
      handler(array) {
        if (array.length) {
          this.addButtonDisabled = false;
        } else {
          this.addButtonDisabled = true;
        }
        this.removeButtonDisabled = true;
      },
      deep: true,
      immediate: true
    },

    availableIndexOptionsToRemove: {
      handler(array) {
        if (array.length) {
          this.removeButtonDisabled = false;
        } else {
          this.removeButtonDisabled = true;
        }
        this.addButtonDisabled = true;
      },
      deep: true,
      immediate: true
    },

    verifyFields: {
      handler(val) {
        if (val) {
          this.addButtonDisabled = true;
          this.removeButtonDisabled = true;
          this.availableIndexOptions = [];
          this.availableIndexOptionsToRemove = [];
        }
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    addSelecteds() {
      let selectedFields = [ ...this.selectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      if (this.availableIndexOptions.length) {
        const avaliableItems = this.availableIndexOptions.map(i => avaliableFields[i]);

        avaliableItems.forEach((el) => {
          selectedFields.push(el);

          const indexToRemove = avaliableFields.findIndex(item => item.id === el.id);
          avaliableFields.splice(indexToRemove, 1);
        });

        selectedFields.sort((a, b) => (a.name > b.name ? 1 : -1));

        avaliableFields.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.availableIndexOptions = [];

        this.verifyIfEnabled();
      }

      this.$emit('selectedOptions', selectedFields);
      this.$emit('availableOptions', avaliableFields);
      this.$emit('updateParttern');
    },
    clearAdd() {
      this.availableIndexOptions = [];
    },
    clearRemove() {
      this.availableIndexOptionsToRemove = [];
    },
    removeSelecteds() {
      let selectedFields = [ ...this.selectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      if (this.availableIndexOptionsToRemove.length) {
        const removeItems = this.availableIndexOptionsToRemove.map(i => selectedFields[i]);

        removeItems.forEach((el) => {
          avaliableFields.push(el);

          const indexToRemove = selectedFields.findIndex(item => item.id === el.id);
          selectedFields.splice(indexToRemove, 1);
        });

        selectedFields.sort((a, b) => (a.name > b.name ? 1 : -1));

        avaliableFields.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.availableIndexOptionsToRemove = [];

        this.verifyIfEnabled();
      }

      this.$emit('selectedOptions', selectedFields);
      this.$emit('availableOptions', avaliableFields);
      this.$emit('updateParttern');
    },
    verifyIfEnabled() {
      if (!this.availableOptions.length) {
        this.addButtonDisabled = true;
      } else {
        this.addButtonDisabled = false;
      }

      if (!this.selectedOptions.length) {
        this.removeButtonDisabled = true;
      } else {
        this.removeButtonDisabled = true;
      }
    }
  }
}
</script>