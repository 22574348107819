import { mapGetters } from "vuex"

export default {
  data: () => ({
    filterOperationalRoutines: {
      carrierId: null,
      financialGroupId: null,
      contractId: null,
      subContractId: null,
      competencyDate: null
    },
    filterConference: {
      uploadType: '',
      carrierId: '',
      financialGroupId: '',
      contractId: '',
      subContractId: '',
      competence: '',
      id: '',
    },
    registerTypes: {
      "PM": "Prêmio",
      "PMI": "Prêmio + Impostos",
      "PMA": "Prêmio Agravo",
      "IP": "Inclusão Pro-rata",
      "EP": "Exclusão Pro-rata",
      "MFE": "Mudança de Faixa Etária",
      "IOF": "Imposto IOF",
      "ISS": "Imposto ISS",
      "IMP": "Imposto (IOF + ISS)",
      "IM": "Inclusões no Mês",
      "IR": "Inclusões Retroativas",
      "RM": "Reativações no Mês",
      "RR": "Reativações Retroativas",
      "TM": "Transferências no Mês",
      "TR": "Transferências Retroativas",
      "AM": "Alterações no Mês",
      "AR": "Alterações Retroativas",
      "RE": "Cobrança de Remido",
      "CM": "Cancelamentos no Mês",
      "CR": "Cancelamentos Retroativos",
      "DM": "Devolução Remido no Mês",
      "DR": "Devolução Remido Retroativa",
      "AD": "Acerto de Fatura - Devolver",
      "AC": "Acerto de Fatura - Cobrar",
      "RS": "Recuperação Sinistro - Coparticipação",
      "REM": "Reembolso",
      "APO": "Aporte (Sinistro)",
      "AJC": "Ajustes do Contrato",
      "AJR": "Ajustes de Retroatividade",
      "TX2": "Taxa de Cartão 2ª Via",
      "TXC": "Taxa de Cartão",
      "OUT": "Outros Lançamentos"
    }
  }),
  methods: {
    getRegisterTypes(type) {
      return this.registerTypes[type] || '—'
    },
  },
  computed: {
    ...mapGetters({
      getConferenceFileTypes: 'operational-routines/fileTypes'
    })
  }
}
