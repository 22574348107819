<template>
	<v-row>
		<v-col
			cols="12"
			class="py-0">
			<p
				class="wineLabel--text pb-0 mb-0"
				style="font-size: 1.125rem;">
				Selecione o contrato desejado:
			</p>
			<v-simple-table
				class="overflow-auto"
				style="
					max-height: 215px;
					border: 1px solid rgba(0, 0, 0, .2);">
				<thead
					class="wineSecondary">
					<tr>
						<th
							v-for="header in headers"
							:key="header.text"
							class="text-left white--text text-subtitle-1 font-weight-medium"
							:style="header.style">
							{{ header.text }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, key) in getInvoiceValidations"
						:key="key"
						@click="selectInvoice(item)"
						:class="{
							'text-subtitle-1 wineLabel--text font-weight-regular': true
						}"
						:style="contractInSelection(item) ? 'background: #EDE5EB' : ''">
						<td
							style="width: 320px">
							{{ item.validationId }}
						</td>
						<td
							style="width: 320px">
							{{ item.financialGroup.name }}
						</td>
						<td
							style="width: auto">
							{{ item.name }}
						</td>
						<td
							class="d-flex justify-end">
							<v-icon
								color="wine"
								v-if="contractInSelection(item)">
								fas fa-check
							</v-icon>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-col>
		<template
			v-if="selectedContracts.length > 0">
			<v-container
				fluid>
				<v-row>
					<v-col
						cols="12"
						class="mt-5">
						<span
							class="wineLabel--text text-subtitle-1">
							Total de prévias de faturas selecionadas:
						</span>
						<PreviewHeader />
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-row>
</template>

<script>
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import PreviewHeader from './PreviewHeader.vue';
import ConferenceCard from './ConferenceCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
	name: "InvoiceConference",

	components: {
		SnackbarCustomize,
		ConferenceCard,
		PreviewHeader,
	},

	data: () => ({
		headers: [
			{
				text: 'Protocolo',
				value: 'financialGroup',
				style: 'width: 320px'
			},
			{
				text: 'Grupo Financeiro',
				value: 'financialGroup',
				style: 'width: 320px'
			},
			{
				text: 'Contrato',
				value: 'contract',
				style: 'width: auto'
			},
			{
				text: '',
				value: '',
				style: 'width: auto'
			},
		],
	}),

	methods: {
		...mapMutations({
			addContract: 'operational-routines/addContract',
			removeContract: 'operational-routines/removeContract',
		}),
		selectInvoice(invoice) {
			if (this.selectedContracts.some(el => el.validationId === invoice.validationId)) {
				this.removeContract(invoice);
				return;
			}

			this.addContract(invoice);
		},
		contractInSelection(contract) {
			return this.selectedContracts.some(el => el.validationId === contract.validationId)
		},
		selectCard(registerKey, type, card) {
			switch (registerKey) {
				case 0:
					this.$emit('selectCard', {
						type: type,
						card: card,
						selectedContracts: this.selectedContracts,
						status: card.status,
						isInvoice: card.isInvoice,
						key: card.key,
						ids: this.getAllRegistryIds(card.key),
					})
					break;
				case 1:
					this.$emit('selectCard', {
						type: type,
						card: card,
						selectedContracts: this.selectedContracts,
						status: card.status,
						isInvoice: card.isInvoice,
						key: card.key,
						ids: this.getAllRegistryIds(card.key),
					})
					break;
				case 2:
					this.$emit('selectCard', {
						type: type,
						card: card,
						selectedContracts: this.selectedContracts,
						status: card.status,
						isInvoice: card.isInvoice,
						key: card.key,
						ids: this.getAllRegistryIds(card.key),
					})
					break;
			}
		},
		mergeLists(currentList, newList) {
			return [ ...currentList, ...newList ];
		},
		reduceList(key) {
			return this.selectedContracts.reduce((currentList, item) => {
				return this.mergeLists(currentList, item[key])
			}, []);
		},
		getAllRegistryIds(key) {
			return this.reduceList(key);
		},
	},

	computed: {
		...mapGetters({
			getInvoiceValidations: 'operational-routines/invoiceValidations',
			selectedContracts: 'operational-routines/selectedContracts'
		}),
	}
}
</script>
